<template>
  <section class="roles">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <div class="row mb-4 justify-content-between align-items-center">
          <h2 class="title mb-0">Administra tus roles</h2>
          <router-link
            v-if="view_permissions.add == true"
            to="/role/new"
            class="btn-select static sm my-1"
          >
            <i class="fas fa-plus" />Nuevo rol
          </router-link>
          <span v-else class="btn-select static disabled sm my-1">
            <i class="fas fa-plus" />Nuevo rol
            <span class="tooltip_text-sm" v-if="view_permissions.export != true">Lo sentimos, no tienes acceso a este módulo.</span>
          </span>
        </div>
        <div class="card card-table">
          <base-table
            class="tabla__agentes"
            :data="roles"
            ref="table"
          >
            <template slot="columns">
              <th>Nombre Rol</th>
              <th>Chatbot</th>
              <th>Plan</th>
              <th>Clientes</th>
              <th>Databot Live</th>
              <th>Conversaciones</th>
              <th>CRM</th>
              <th>Preguntas</th>
              <th>Whatsapp</th>
              <th>Acciones</th>
            </template>

            <template slot-scope="{ row }">
              <td>{{ row.name }}</td>
              <td>
                <i
                  :class="row.bot_create ? 'text-success' : 'text-gris'"
                  class="fas fa-plus"
                />
                <i
                  :class="row.bot_read ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
                <i
                  :class="row.bot_update ? 'text-success' : 'text-gris'"
                  class="fas fa-pen"
                />
                <i
                  :class="row.bot_delete ? 'text-success' : 'text-gris'"
                  class="fas fa-pen"
                />
              </td>
              <td>
                <i
                  :class="row.plan_create ? 'text-success' : 'text-gris'"
                  class="fas fa-plus"
                />
                <i
                  :class="row.plan_read ? 'text-success' : 'text-gris'"
                  class="fas fa-plus"
                />
                <i
                  :class="row.plan_update ? 'text-success' : 'text-gris'"
                  class="fas fa-pen"
                />
                <i
                  :class="row.plan_delete ? 'text-success' : 'text-gris'"
                  class="fas fa-trash"
                />
              </td>
              <td>
                <i
                  :class="row.cli_create ? 'text-success' : 'text-gris'"
                  class="fas fa-plus"
                />
                <i
                  :class="row.cli_read ? 'text-success' : 'text-gris'"
                  class="fas fa-plus"
                />
                <i
                  :class="row.cli_update ? 'text-success' : 'text-gris'"
                  class="fas fa-pen"
                />
                <i
                  :class="row.cli_delete ? 'text-success' : 'text-gris'"
                  class="fas fa-trash"
                />
              </td>
              <td>
                <i
                  :class="row.databot_read ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
              </td>
              <td>
                <i
                  :class="row.conver_read ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
              </td>
              <td>
                <i
                  :class="row.crm ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
              </td>
              <td>
                <i
                  :class="row.questions ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
              </td>
              <td>
                <i
                  :class="row.whatsapp ? 'text-success' : 'text-gris'"
                  class="fas fa-eye"
                />
              </td>
              <td width="10px">
                <a type="button">
                  <i @click="mostrar(row.id)" class="fas fa-edit" />
                </a>
                <span @click="deleteRol(row)">
                  <i>
                    <img class="table-icons i-delete" src="/img/icons/5-eliminar.svg" alt="Eliminar" />
                  </i>
                </span>
              </td>
            </template>
          </base-table>
        </div>
        <div class="col-lg-4 px-0 my-5">
          <router-link class="btn-text" to="/agentes">&lt;- Volver</router-link>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
    <Modal
      :show="show"
      @close="show = false"
    >
      <template slot="header">
        <h2>Editar Roles</h2>
      </template>
      <EditarRol
        slot="content"
        :rol="rol_id"
        @close="show = false"
      />
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import EditarRol from './edit';
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    EditarRol,
    FadeTransition
  },
  data() {
    return {
      roles: [],
      show: false,
      rol_id: 25,
      loading: true
    };
  },
  methods: {
    // Borrar un rol de un bot
    deleteRol(rol) {
      const self = this;
      self
        .$swal({
          title: '¿Estás seguro?',
          text: 'No podrás revertir esta acción',
          icon: 'warning',
          buttons: {
            cancel: {
              text: 'Cancelar',
              value: false,
              visible: true,
              className: '',
              closeModal: true,
            },
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        })
        .then((result) => {
          console.log('deleted rol', result);
          if (result) {
            dashboard_api
              .delete(`/role/destroyrole/${rol.id}`)
              .then((response) => {
                let contador = 0;
                self.roles.forEach((element) => {
                  if (element.id == rol.id) {
                    self.roles.splice(contador, 1);
                  }
                  contador += 1;
                });
              });
          }
        });
    },
    mostrar(val) {
      this.rol_id = val;
      console.log('val', val);
      this.show = true;
    },
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'view_permissions', 'all_roles']),

    roles() {
      if (this.all_roles?.length > 0) {
        return this.all_roles.filter(el => el.name != 'propietario');
      }
    },
  },

  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.roles {
  .card-table {
    max-height: auto;
    height: auto;
    @media (max-width: 1600px) {
      max-height: auto;
      height: auto;
    }
  }
  .i-edit {
    width: .9rem;
  }
  .i-delete {
    width: .7rem;
  }
  .tooltip_text-sm {
    top: -100%;
    left: -.25rem;
    right: 0;
    width: 110%;

    &::after {
      content: '';
      display: flex;
      align-items: center;
      margin: 0;
      position: absolute;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }
  .btn-select:hover {
    .tooltip_text-sm {
      visibility: visible;
      transition: 0.25s;
      opacity: 1;
      z-index: 250 !important;
      top: -1rem;
      left: -.25rem;
      width: 110%;
    }
  }
}
</style>
