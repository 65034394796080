<template>
  <section class="editar-rol">
    <form role="form">
      <label>Nombre del Rol</label>
      <input class="mb-3" placeholder="Nombre del rol" v-model="model.name" />
      <label for="Permisos">Permisos</label>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr class="text-center">
              <th scope="col"></th>
              <th scope="col"><i class="fas fa-plus" /> Crear</th>
              <th scope="col"><i class="fas fa-eye" /> Ver</th>
              <th scope="col"><i class="fas fa-pen" /> Editar</th>
              <th scope="col"><i class="fas fa-trash" /> Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-left">
              <td>Chatbot</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_create"
                  value="bot_create"
                  v-model="model.bot_create"
                />
              </td>
              <td class="text-center">
                <input type="checkbox" id="bot_read" value="bot_read" v-model="model.bot_read" />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_update"
                  value="bot_update"
                  v-model="model.bot_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="bot_delete"
                  value="bot_delete"
                  v-model="model.bot_delete"
                />
              </td>
            </tr>
            <tr class="text-left">
              <td>Plan</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_create"
                  value="plan_create"
                  v-model="model.plan_create"
                />
              </td>
              <td class="text-center">
                <input type="checkbox" id="plan_read" value="plan_read" v-model="model.plan_read" />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_update"
                  value="plan_update"
                  v-model="model.plan_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="plan_delete"
                  value="plan_delete"
                  v-model="model.plan_delete"
                />
              </td>
            </tr>
            <tr class="text-left">
              <td>Cliente</td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cli_create"
                  value="cli_create"
                  v-model="model.cli_create"
                />
              </td>
              <td class="text-center">
                <input type="checkbox" id="cli_read" value="cli_read" v-model="model.cli_read" />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cli_update"
                  value="cli_update"
                  v-model="model.cli_update"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="cli_delete"
                  value="cli_delete"
                  v-model="model.cli_delete"
                />
              </td>
            </tr>
            <tr class="text-left">
              <td>Databot Live</td>
              <td><p class="text-center mb-0">-</p></td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="databot_read"
                  value="databot_read"
                  v-model="model.databot_read"
                />
              </td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
            </tr>
            <tr class="text-left">
              <td>Conversaciones</td>
              <td><p class="text-center mb-0">-</p></td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="conver_read"
                  value="conver_read"
                  v-model="model.conver_read"
                />
              </td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>

              <td class="text-center"><p class="text-center mb-0">-</p></td>
            </tr>
            <tr class="text-left">
              <td>CRM</td>
              <td><p class="text-center mb-0">-</p></td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="crm"
                  value="crm"
                  v-model="model.crm"
                />
              </td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
            </tr>
            <tr class="text-left">
              <td>Preguntas Frecuentes</td>
              <td><p class="text-center mb-0">-</p></td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="quiestions"
                  value="quiestions"
                  v-model="model.questions"
                />
              </td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
            </tr>
            <tr class="text-left">
              <td>Whatsapp</td>
              <td><p class="text-center mb-0">-</p></td>
              <td class="text-center">
                <input
                  type="checkbox"
                  id="quiestions"
                  value="quiestions"
                  v-model="model.whatsapp"
                />
              </td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
              <td class="text-center"><p class="text-center mb-0">-</p></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-center">
        <button class="btn btn-base md" @click="editarrol">Guardar</button>
      </div>
    </form>
  </section>
</template>

<script>
import dashboard_api from "@/dashboard_api.js";

export default {
  name: "EditarRoles",
  props: ["rol"],
  data() {
    return {
      model: {
        name: "",
        bots_id: "",
        bot_create: false,
        bot_read: false,
        bot_update: false,
        bot_delete: false,
        plan_create: false,
        plan_read: false,
        plan_update: false,
        plan_delete: false,
        cli_create: false,
        cli_read: false,
        cli_update: false,
        cli_delete: false,
        databot_read: false,
        conver_read: false,
        crm: false,
        questions: false,
        whatsapp: false
      },
      error: ""
    };
  },

  methods: {
    // Editar un Rol del bot
    editarrol() {
      const self = this;
      this.error = "";
      self.loading = true;
      if (this.model.name === "") {
        self.$swal({
          icon: "error",
          title: "El nombre no puede estar vacio"
        });
      } else {
        dashboard_api
          .put(`/role/updaterole/${self.rol}`, {
            id: self.id_role,
            name: this.model.name,
            bots_id: this.bots_id,

            bot_create: this.model.bot_create,
            bot_read: this.model.bot_read,
            bot_update: this.model.bot_update,
            bot_delete: this.model.bot_delete,
            plan_create: this.model.plan_create,
            plan_read: this.model.plan_read,
            plan_update: this.model.plan_update,
            plan_delete: this.model.plan_delete,
            cli_create: this.model.cli_create,
            cli_read: this.model.cli_read,
            cli_update: this.model.cli_update,
            cli_delete: this.model.cli_delete,
            databot_read: this.model.databot_read,
            conver_read: this.model.conver_read,
            crm: this.model.crm,
            questions: this.model.questions,
            whatsapp: this.model.whatsapp
          })
          .then(result => {
            if (result.statusText == "OK") {
              self.$swal({
                icon: "success",
                title: "Rol Editado",
                text: "¡Rol Editado Correctamente!"
              });
            }
          })
          .catch(err => {
            if (err == "Error: Not Acceptable") {
              self.$swal({
                icon: "error",
                title: "El nombre del rol ya esta ingresado a este bot"
              });
            } else {
              self.$swal({
                icon: "error",
                title: "Error al editar el rol"
              });
            }
          });
      }
    }
  },

  created() {
    window.scrollTo(0, 0);
  },
  watch: {
    rol() {
      const self = this;
      dashboard_api
        .get(`/role/showrole/${self.rol}`)
        .then(result => {
          self.model = result.data;
          self.bots_id = result.data.bots_id;
          // console.log(result.data)
        })
        .catch(response => {
          console.log(response);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.editar-rol {
  text-align: left;
  padding: 2rem;

  .md {
    width: 50%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }
  table {
    margin-bottom: 2rem;

    td {
      padding: 10px;
      color: #767676;
    }
    th {
      text-transform: capitalize;
      letter-spacing: normal;
      font-size: 12px;
      color: #767676;
      padding: 10px 0;

      i {
        font-size: 0.5;
        margin-right: 0.5;
        color: #767676;
      }
    }
    tr {
      background-color: #fff;
    }
  }
}
</style>
