<template>
  <section v-if="selected_bot" class="tabla-conocimiento">
    <div class="row justify-content-between align-items-center b-bottom">
      <h3 class="my-2">Carga de conocimientos</h3>
      <FadeTransition :duration="100">
        <div class="row align-items-center">
          <template v-if="knowledge.length > 0 || (dateFilter != '' && knowledge.length == 0)">
            <!-- Eliminar todos -->
            <div v-if="checkedRows?.length > 0" @click="deleteFile" class="d-flex align-items-center my-0 mx-2">
              <span class="btn-text text-danger my-1 mr-3">
                <i class="fas fa-times" />Eliminar {{ checkedRows.length }}
              </span>
            </div>
            <!-- DATEPICKER -->
            <div class="input_box">
              <span class="btn-date" @click="clearDate">
                <i class="fas fa-times mb-0 mr-0" />
              </span>
              <input type="date" class="input_date sm my-1 mr-3" @change="filterByDate" v-model="dateFilter"/>
            </div>
            <!-- SearchBar -->
            <div class="input_box">
              <span class="btn-date px-2">
                <i class="fas fa-search mr-0" />
              </span>
              <input placeholder="Buscar" type="search" class="input_search sm mb-0 mr-3" />
            </div>
          </template>
          <!-- UPLOAD FILE -->
          <button
            :class="{
              'btn-select static sm my-2 d-flex justify-content-center align-items-center': true,
              disabled: plan_limit
            }"
            :disabled="plan_limit"
            @click="showModal = true"
          >
            <img src="/img/icons/1-upload-file.svg" class="pr-2">
            Subir archivo
            <span v-if="plan_limit" class="tooltip_text-sm for_disabled">Mejora tu plan para cargar más documentos.</span>
          </button>
        </div>
      </FadeTransition>
    </div>
    <SpinnerLoading v-if="loading" />
    <template v-else-if="dateFilter != '' && knowledge.length == 0">
      <div class="no_data h-100">
        <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
        <p class="text-center text_sm my-3">
          No hay documentos con esa fecha.
        </p>
      </div>
    </template>
    <template v-else-if="knowledge.length > 0">
      <section class="table buefy">
        <b-table :range-before="rangeBefore" :range-after="rangeAfter" :data="knowledge" :per-page="perPage"
          :current-page.sync="currentPage" :pagination-simple="true" :pagination-position="'bottom'"
          :default-sort-direction="'desc'" :sort-icon="'caret-up'" aria-next-label="Next page"
          aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" icon-pack="fas"
          :key="tableKey" :checked-rows.sync="checkedRows" :selected.sync="selectedRow" :sticky-header="stickyHeaders"
          height="500" checkable focusable ref="bTable">
          <template slot-scope="props">
            <b-table-column field="name" label="Nombre del documento" sortable>
              <p @dblclick="changen('name')" class="e-show">
                <router-link :to="'/documento-conocimiento/detail/' + props.row._id" class="mr-2">
                  <span>
                    <i class="far fa-file"></i>
                    {{ props.row.title }}
                  </span>
                </router-link>
                <span @click="setShowDocumentContent(props.row)" :class="{
                  'i-btn i-chat-2 i-btn-sm i-st i-btn-hover': true,
                  active: props.row._id == selectedRow._id
                }"></span>
              </p>
            </b-table-column>
            <b-table-column field="type_file" label="Tipo" sortable>
              <div v-if="props.row.doc_type">
                <span>{{ props.row.doc_type.toUpperCase() }}</span>
              </div>
            </b-table-column>
            <b-table-column field="url" label="URL" sortable>
              <div v-if="props.row.url" class="w_limit" :title="props.row.url">
                <a class="link" :href="props.row.url" target="_blank">{{props.row.url}}</a>
              </div>
            </b-table-column>
            <b-table-column field="authorized" label="Autorizado" sortable>
              <div v-if="props.row.user?.name">
                <span>{{ props.row.user?.name }}</span>
              </div>
            </b-table-column>
            <b-table-column field="created" label="Creado" sortable>
              <div v-if="props.row.created_at">
                <span>{{ $moment(props.row.created_at).format("DD/MM/YYYY - hh:mm a") }}</span>
              </div>
            </b-table-column>
            <b-table-column field="updated" label="Modificado" sortable style="min-width: 450px;">
              <div v-if="props.row.updated_at" class="d-flex align-items-center justify-content-start">
                <span>{{ $moment(props.row.updated_at).format("DD/MM/YYYY - hh:mm a") }}</span>
                <div @click="syncDocument(props.row)" class="ml-5 p-1 sync-btn" :style="props.row._id == selectedRow._id && 'display: block !important'">
                  <img src="/img/icons/1-sync.svg" class="mr-2" style="width: 15px;" />
                  <span>Sincronizar</span>
                </div>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </section>
    </template>
    <template v-else-if="!plan_limit">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <div class="no_data p-5">
          <img src="/img/icons/1-upload-cloud.svg" >
          <input v-model="link" type="text" class="line-input mt-3" placeholder="Ingresa un link para cargar información" @keyup="uploadLink"/>
          <span>O</span>
          <div>
            <input type="file" class="d-none" ref="input_file" @change="inputFile">
            <button
              class="btn-select static sm my-2 d-flex justify-content-center align-items-center"
              @click="buttonFile"
            >
              <img src="/img/icons/1-upload-file.svg" class="pr-2">
              Subir archivo
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <div class="no_data p-5">
          <span>Debes mejorar tu plan</span>
        </div>
      </div>
    </template>

    <div class="table-footer">
      <div class="col-md-4 pl-0">
        <span class="text_sm mr-3 mb-0">
          Visibles: {{ knowledge.length == 1 ? knowledge.length + " Documento." : knowledge.length + " Documentos." }}
        </span>
      </div>
      <div class="col-md-4 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
          <p
            class="pagination__firstPage my-auto mx-4"
            :class="currentPage != 1 && 'allowed'"
            :style="`cursor: ${currentPage == 1 ? 'not-allowed' : 'pointer'}`"
            @click="currentPage != 1 && getFiles(1)">
            1
          </p>
          <div class="number-input my-auto">
            <button
              class="btn-left left-arrow py-1 px-3"
              type="button"
              id="button-addon1"
              @click="currentPage != 1 && getFiles(Number(currentPage) - 1)"
              :disabled="currentPage == 1">
            </button>
            <div>
              <input
                class="input_page m-0"
                :class="{ input_error: errorInputPage }"
                type="number"
                :max="pages"
                v-model="input_page"
                error="true"
              />
            </div>
            <button
              class="btn-right right-arrow py-1 px-3"
              type="button"
              id="button-addon2"
              :disabled="currentPage == pages"
              @click="currentPage != pages && getFiles(Number(currentPage) + 1)">
            </button>
          </div>
          <p
            class="pagination__lastPage my-auto mx-4"
            :class="currentPage != pages && 'allowed'"
            :style="`cursor: ${currentPage == pages ? 'not-allowed' : 'pointer'}`"
            @click="currentPage != pages && getFiles(pages)"
          >
            {{ pages }}
          </p>
        </div>
        <span v-if="errorInputPage" class="text_sm text_error text-center mx-auto mt-2">
          El valor ingresado debe ser entre 1 y {{ pages }}
        </span>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-end pr-0">
        <span v-if="totalDocuments" class="text_sm mb-0 mr-3">{{ totalDocuments }} {{ totalDocuments == 1 ? 'documento' : 'documentos'}} en total.</span>
        <select name="items" class="select_text my-1 mr-3" @change="getFiles(1)" v-model="perPage">
          <option value="15">15 documentos por página</option>
          <option value="30">30 documentos por página</option>
          <option value="50">50 documentos por página</option>
        </select>
        <!-- <template>
          <button class="btn-select static sm my-0 sm d-flex align-items-center">Exportar datos</button>
        </template> -->
      </div>
    </div>

    <!-- PREVIEW -->
    <template v-if="showDocumentContent">
      <section class="preview">
        <div class="preview-head">
          <div class="label-box">
            <div>
              <label class="mb-0" for="">Contenido</label>
            </div>
            <div class="d-flex align-items-center">
              <!-- <button class="export-btn px-2 py-1">Exportar</button> -->
              <span class="i-btn i-close i-btn-sm i-sm" @click="showDocumentContent = false"></span>
            </div>
          </div>
        </div>
        <SpinnerLoading v-if="loading" />
        <FadeTransition v-else :duration="200">
          <div class="preview-body px-4 w-100">
            <div class="text-br" v-if="selectedRow.has_segments">
              <div
              v-for="(segment,idy) in selectedRow.documents_segments"
              :key="segment._id"
              class="mb-3"
            >
            <hr v-if="idy > 0" class="my-4">
            <label class="mb-3">Página {{ segment.page }}</label>
              <div class="text-break">
                {{ segment.content }}
              </div>
            </div>
            </div>
            <div class="text-break" v-else>
              {{ selectedRow.content }}
            </div>
          </div>
        </FadeTransition>
      </section>
    </template>
    <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="showModal = false">
      <template slot="header">
        <h3>Subir archivo</h3>
      </template>
      <template slot="content">
        <div class="d-flex flex-column justify-content-center align-items-center p-5">
          <!-- <i class="fas fa-upload m-0 upload-icon"></i> -->
          <img src="/img/icons/1-upload-cloud.svg" >
          <input v-model="link" type="text" class="line-input link mt-3" placeholder="Ingresa un link para cargar información" @keyup="uploadLink"/>
          <span>O</span>
          <div>
            <input type="file" class="d-none" ref="input_file" @change="inputFile">
            <button
              :class="{
                'btn-select static sm my-2 d-flex justify-content-center align-items-center': true,
                disabled: plan_limit
              }"
              :disabled="plan_limit"
              @click="buttonFile"
            >
              <img src="/img/icons/1-upload-file.svg" class="pr-2">
              Subir archivo
              <span v-if="plan_limit" class="tooltip_text-sm for_disabled">Mejora tu plan para cargar más documentos.</span>
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import axios from "axios";
import { SlideYUpTransition, FadeTransition } from 'vue2-transitions';
import { mapState } from 'vuex';
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';
import environment from '@/environment.js';
import dashboard_api_v2 from '@/dashboard_api_v2';
import dashboard_api from '@/dashboard_api.js';

export default {
  name: 'knowledge-index',
  components: {
    FadeTransition,
    SlideYUpTransition
  },
  computed: {
    ...mapState(['selected_bot', 'user', 'plan_name']),
  },
  data() {
    return {
      loading: false,
      knowledge: [],
      newFile: null,
      assistantId: '',
      input_page: this.actual_page,
      errorInputPage: false,
      pages: 1,
      totalDocuments: 0,
      showModal: false,
      checkedRows: [],
      rangeBefore: 1,
      rangeAfter: 1,
      customProperties: [],
      filter_agent_id: '',
      tableKey: 0,
      stickyHeaders: true,
      selectedRow: {},
      perPage: 15,
      currentPage: 1,
      showDocumentContent: false,
      link: '',
      dateFilter: '',
      dateFilterFormated: '',
      plan_limit: false,
      plans: []
    };
  },
  async mounted() {
    // this.getPlans();
    try {
      await this.getAssistantId();
      await this.getFiles(1);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getPlans() {
      this.plans = (await dashboard_api.get('/base_plans/with_channels')).data;
    },
    // planRestrictions(total_docs) {
    //   let starter = 1;
    //   let growth = 10;
    //   let professional = 200;
    //   let corporate = 500;
    //   let enterprise = 9999999999999;

    //   console.log("plan restrictions->", total_docs, this.plan_name);
    //   // with stripe
    //   // switch (this.plan_name.toString()) {
    //   //   case 'plan starter':
    //   //     return this.plan_limit = total_docs < starter;
    //   //   case 'plan growth':
    //   //     return this.plan_limit = total_docs < growth;
    //   //   case 'plan professional':
    //   //     return this.plan_limit = total_docs < professional;
    //   //   case 'plan corporate':
    //   //     return this.plan_limit = total_docs < corporate;
    //   //   case 'plan enterprise':
    //   //     return this.plan_limit = total_docs < enterprise;
    //   //   default:
    //   //     return this.plan_limit = false;
    //   // }

    //   switch (this.plan_name.toLowerCase()) {
    //     case 'plan starter-web4':
    //       return this.plan_limit = total_docs < starter;
    //     case 'plan growth':
    //       return this.plan_limit = total_docs < growth;
    //     case 'plan profesional-web1':
    //       return this.plan_limit = total_docs < professional;
    //     case 'plan profesional-web2':
    //       return this.plan_limit = total_docs < professional;
    //     case 'plan corporate-web2':
    //       return this.plan_limit = total_docs < corporate;
    //     case 'plan enterprise-web3':
    //       return this.plan_limit = total_docs < enterprise;
    //     default:
    //       return this.plan_limit = false;
    //   }
    // },
    clearDate(e) {
      this.dateFilter = '';
      this.dateFilterFormated = '';
      this.getFiles(1);
    },
    async filterByDate(e) {
      this.dateFilter = e.target.value;
      this.dateFilterFormated = new Date(e.target.value);
      await this.getFiles(1);
    },
    async uploadLink(e) {
      if (e.keyCode == 13) {
        this.loading = true;
        this.showModal = false;
        await axios.post(`${environment.RASA_URL}/api/assistants/${this.assistantId}/load_knowledge_documents`, {
          files: [{
            url: this.link
          }],
          user: this.user
        });
        this.link = '';
        await this.getFiles(1);
      }
    },
    async getAssistantId() {
      try {
        this.loading = true;
        const res = await axios.get(`${environment.RASA_URL}/api/assistants/by_bot_id/${this.selected_bot.id}`);
        this.assistantId = res.data.payload.assistant_id;
        console.log("this.assistantId->", this.assistantId);
      } catch (error) {
        console.error("error getAssistantId", error);
      }
    },
    buttonFile() {
      this.$refs.input_file.click();
    },
    setShowDocumentContent(document) {
      if (document._id == this.selectedRow._id) this.showDocumentContent = !this.showDocumentContent;
      else {
        this.showDocumentContent = true;
        this.selectedRow = document;
      }
    },
    async inputFile() {
      try {
        this.newFile = this.$refs.input_file.files[0];
        let formData = new FormData();
        formData.append('file', this.newFile);
        this.uploadFile(formData);
      } catch (error) {
        console.log(error);
      }
    },
    async syncDocument(document) {
      try {
        this.loading = true;
        console.log("document", document);
        await axios.post(`${environment.RASA_URL}/api/assistants/${this.assistantId}/load_knowledge_documents`, {
          is_sync: true,
          files: [{
            name: document.title,
            url: document.url
          }],
          user: this.user
        });
        this.getFiles(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async uploadFile(formData) {
      try {
        this.loading = true;
        this.showModal = false;
        const res = (
          await dashboard_api_v2.post('process_files/upload_to_official_bucket', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        ).data;
        const file = {
          name: res.name,
          url: res.url.url
        };
        await axios.post(`${environment.RASA_URL}/api/assistants/${this.assistantId}/load_knowledge_documents`, {
          files: [file],
          user: this.user
        });
        await this.getFiles(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.link = '';
        this.loading = false;
      }
    },
    async getFiles(pageNumber) {
      this.currentPage = pageNumber;
      this.input_page = pageNumber;
      this.loading = true;

      let params = {
        sort: "created_at",
        order: "desc",
        page: this.currentPage,
        limit: this.perPage,
      };

      if (this.dateFilterFormated) params.date = this.dateFilterFormated;

      const res = await axios.get(`${environment.RASA_URL}/api/assistants/${this.assistantId}/documents`, {
        params
      });

      this.knowledge = res.data.payload;
      this.pages = res.data.totalPages;
      this.totalDocuments = res.data.totalDocs;
      // if (this.plan_name?.length) this.planRestrictions(res.data.totalDocs)
      this.loading = false;
    },
    async deleteFile() {
      try {
        this.loading = true;
        for (const document of this.checkedRows) {
          await axios.delete(`${environment.RASA_URL}/api/assistants/${this.assistantId}/documents/${document._id}`);
        }
        this.checkedRows = [];
        await this.getFiles(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tabla-conocimiento {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-rows: auto 2fr 90px;

  .bg-spinner {
    position: relative;
    height: calc(80vh - 90px);
  }

  .table {
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;
      margin-bottom: 1rem;
      transition: 0.5s;

      /* Cambiamos el fondo en hover */
      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }

      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
    .w_limit {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 450px;
      width: 100%;
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
  }

  .icon_rating {
    width: 1.5rem;
  }

  .select-css {
    background-color: #767676;
    display: block;
    font-size: 12px !important;
    outline: none;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-align-last: center;
    letter-spacing: none;
    padding: 0.25rem 0.5rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: none;
    text-transform: capitalize;
    border-radius: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none !important;
    box-shadow: none !important;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 30%;
    }
  }

  .fa-whatsapp {
    font-size: 0.9rem;
    margin-right: 0.2rem;
    color: #00bb2d;

    &:hover {
      color: #767676;
      transition: 0.5s;
    }
  }

  .fa-whatsapp.red {
    color: #ff6565;
  }

  .input_page {
    width: 80px;
  }

  .no-state {
    text-transform: capitalize;
    font-size: 12px;
    color: #b2b2b2;
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: auto 0;
    border-radius: 0;
  }

  .tooltip_text {
    background-color: #fafafa;
    border: 1px solid #cdcdcd;
    color: #767676;
    text-transform: none;
    text-align: center;
    white-space: normal;
    font-size: 10px;
    width: 113px;
    padding: 0.25rem;
    bottom: -0.44rem;
    left: -1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }

  .no-state_custom {
    width: 100px;
    justify-content: left;
  }

  .select_sm {
    font-size: 12px;
    color: #767676;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .column-input {
    width: auto;
    appearance: none;
    padding: 0.25rem 0.5rem;
    margin: 0;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    font-family: 'Manrope', 'sans-serif';
  }

  .multiselect {
    @media (max-width: 1024px) {
      max-width: 1000px;
      width: 100%;
      font-size: 12px;
    }

    .checkbox-id {
      width: 190px;
      top: 40px;
      left: 0.5rem;
      border: 1px solid #e8e8e8;
      border-radius: 0.5rem;
      padding-bottom: 0;

      .checkboxes-item {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        color: #767676;
        font-weight: 400;
        padding: 0.25rem 0.75rem;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .no_data {
    min-width: 1000px;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed #ddd;
    border-radius: 10px;

    @media (max-width: 1200px) {
      min-width: 100%;
    }
  }

  .list-w {
    width: 120px !important;
  }

  span.tag-text {
    padding: 1px 10px 1px 10px;
  }

  .text-state-fill {
    display: inline-block;
    margin: 0 0.5rem 0 0;
  }

  .tooltip-custom {
    z-index: 4;
  }

  .width-column {
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .upgrade-label {
    font-size: 10px;
    display: flex;
    align-items: center;
    border: none;
    padding: .2rem .75rem !important;
    top: 1.5rem;
    right: 0;
    height: 22px;
    cursor: pointer;
  }

  .i-btn-hover {
    opacity: 0;
    transition: .25s;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .e-show:hover {
    .i-btn-hover {
      opacity: 1;
    }
  }

  .flag-w {
    min-width: 40px;
  }

  .tooltip_text-sm {
    top: -100%;
    left: -.25rem;
    right: 0;
    width: 110%;

    &::after {
      content: '';
      display: flex;
      align-items: center;
      margin: 0;
      position: absolute;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }

  .third-outline:hover {
    .tooltip_text-sm {
      visibility: visible;
      transition: 0.25s;
      opacity: 1;
      z-index: 250 !important;
      top: -1rem;
      left: -.25rem;
      width: 110%;
    }
  }

  // .third-outline .tooltip_text-sm {
  //   opacity: 0;
  //   visibility: hidden;
  // }
  .line-input {
    border: none;
    border-bottom: 2px solid #ddd;
    border-radius: 0;
    width: 300px;

    &::placeholder {
      text-align: center;
    }

    @media (max-width: 360px) {
      width: 200px;
    }
  }

  .upload-icon {
    font-size: 3rem;
  }

  .sync-btn {
    border: 1px solid #2981ef;
    border-radius: 5px;
    display: none;

    & span {
      color: #2981ef
    }
  }
}

.preview {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 480px;
  background-color: #fff;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  z-index: 201;
  animation-name: test;
  animation-duration: .25s;
  overflow-y: hidden;

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    background-color: #fafafa;
    height: 80px;
    border-bottom: 1px solid #e8e8e8;
  }

  &-body {
    padding: 2rem 0 4rem;
    height: calc(92.25vh);
    overflow-y: scroll;
    background-color: #fff;
  }
}

.export-btn {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #666
}
</style>
