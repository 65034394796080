<template>
  <div class="options">
    <div
      v-for="(option, index) in options"
      :key="option.id"
      :draggable="true"
      @dragstart="handleDragStart(index)"
      @dragover="handleDragOver"
      @dragend="handleDragEnd"
      @drop="handleDrop(index)"
    >
      <p
        class="text_error"
        v-if="option.label == 0 && error.empty == 'empty_field'"
      >
        Completa esta opción
      </p>
      <div v-else class="item mb-4">
        <div
          class="cart-label-top delete-input delete-input-option"
          :class="{
            'cart-label-top_dragged': draggedItem == index,
            'delete-input-option_dragged':draggedItem == index
          }"
        >
          <p
            class="label-cart text_error"
            v-if="option.empty_label"
          >
            Completa el nombre
          </p>
          <p
            class="label-cart text_error"
            v-else-if="option.duplicate"
          >
            Este nombre ya existe
          </p>
          <label v-else class="mt-1 label-cart"
            >Opción
            {{
              buttonIndexString
                ? `${buttonIndexString}.${index + 1}`
                : index + 1
            }}</label
          >
          <button @click="deleteOption(option, index)">
            <i class="fas fa-trash-alt" />
          </button>
        </div>
        <div class="row options-row pt-3 pb-2 activate-select" :class="{'options-row_dragged': draggedItem == index}">
          <div class="d-flex flex-column col-lg-6">
            <div>
              <input
                type="text"
                v-model="option.label"
                :placeholder="placeholderOptions[index]"
                :class="{
                  input_error:
                    option.empty_label ||
                    option.duplicate
                }"
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <select class="col-12" v-model="option.action" @change="addSubOptionAuto(option, index, option.action)">
                <option value selected disabled>Tipo de botón</option>
                <option
                  v-for="(botton, index) in optionButton"
                  :key="index"
                  :value="botton"
                >
                  {{ getButtonTypeName(botton) }}
                </option>
              </select>
            </div>
          </div>
          <!-- Mostrar el segundo select si 'answer' está seleccionado -->
          <div class="col-lg-12">
            <select
              v-model="option.selectedIntent"
              v-if="option.action === 'answer'"
            >
              <option value="" disabled selected>Seleccionar intent</option>
              <option
                :value="intent"
                v-for="intent in intents"
                :key="intent.id"
              >
                {{ intent.intent_ui_name }}
              </option>
            </select>
          </div>
          <!-- Mostrar el segundo menu si 'buttons' está seleccionado -->
          <div class="col-12 pb-2" v-if="option.action === 'buttons'">
            <RecursiveInitialMenuOptions
              :buttonIndex="index + 1"
              :buttonIndexString="
                buttonIndexString
                  ? `${buttonIndexString}.${index + 1}`
                  : `${index + 1}`
              "
              :options="option.options || []"
              :level="level + 1"
            />
            <button
              @click="addSubOption(option, index)"
              v-if="
                (option.options?.length < 10) || !option.options
              "
              class="btn btn-dash btn-dash-sm m-0 mt-2"
            >
              +
            </button>
          </div>
          <!-- Mostrar el input si 'url' está seleccionado -->
          <div class="col-lg-12">
            <input
              v-if="option.action === 'url'"
              type="text"
              class="col-lg-12"
              placeholder="Ingresar url"
              v-model="option.url"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecursiveInitialMenuOptions from '@/components/RecursiveInitialMenuOptions.vue';
import { mapState } from 'vuex';

export default {
  name: 'RecursiveInitialMenuOptions',
  components: {
    RecursiveInitialMenuOptions,
  },
  props: {
    buttonIndex: {
      type: Number,
      default: 0,
    },
    buttonIndexString: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      intents: [],
      optionButton: ['answer', 'buttons', 'url', 'ia'],
      placeholderOptions: [
        '🔩 Opciones',
        '🔥 Opciones',
        '🛠️ Opciones',
        '⚙️ Opciones',
        '🧰 Opciones',
        '🪛 Opciones',
        '📍 Opciones',
        '🧑‍💻 Opciones',
      ],
      error: {
        name: '',
        web: '',
        empty: '',
      },
      draggedItem: null,
    };
  },
  mounted() {
    this.getIntents();
  },
  computed: {
    ...mapState([
      'selected_bot'
    ]),
    bot_id() {
      return this.selected_bot.id;
    }
  },
  methods: {
    async getIntents() {
      let intentsList = await this.$store.dispatch(
        'dtClientIntentsModule/listByBotId',
        this.bot_id,
      );
      // filter only intents which start with FAQ. and CUSTOM.
      if (intentsList && intentsList.length) {
        let unicaInternaMostrar = "INTERNA.contacto_ejecutiva";
        let regexToTest = /INTERNA.contacto_ejecutiva/g;
        intentsList = intentsList.filter(
          (intent) =>
            regexToTest.test(intent.intent_name) ||
            intent.intent_name.startsWith('FAQ.') ||
            intent.intent_name.startsWith('CUSTOM.'),
        );
      }
      this.intents = intentsList;
    },
    deleteParentOption(option, index) {
      this.$swal({
        title: '¿Eliminar opción?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          this.$swal({
            icon: "info",
            title: "Borrando opción...",
            showConfirmButton: false
          });
          this.$swal.showLoading();

          if (option.value.input.text && option.value.input.text !== '') {
            await this.$store.dispatch(
              'dtClientNodesModule/deleteButtonOption',
              {
                bot_id: this.bot_id,
                node_name: option.value.input.text,
              },
            );
          }
          this.$swal({
            title: 'Opción eliminada',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          this.options.splice(index, 1);
        }
      });
    },
    deleteOption(option, index) {
      console.log('index -----> :', index);
      console.log('EL NIVEL: ', this.level);
      if (this.level === 0) this.deleteParentOption(option, index);
      else {
        this.$swal({
          title: '¿Eliminar sub opción?',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then(async (resp) => {
          if (resp.isConfirmed) {
            this.options.splice(index, 1);

            this.$swal({
              icon: 'success',
              title: 'Opción eliminada',
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 1000
            });
            if (option && option.label.length > 0) {
              await this.$store.dispatch('dtClientNodesModule/deleteSubOption', {
                bot_id: this.bot_id,
                option,
                suboption: this.options[index],
              });
            }
          }
        });
      }
    },
    getButtonTypeName(type) {
      switch (type) {
        case 'answer':
          return 'Respuesta';
        case 'buttons':
          return 'Botones';
        case 'url':
          return 'URL';
        case 'ia':
          return 'Respuesta generada por IA';
        default:
          return 'Tipo de botón';
      }
    },
    generateLevelString(parentLevel, index) {
      return parentLevel
        ? `${this.buttonIndexString}.${index + 1}`
        : `${index + 1}`;
    },
    addSubOptionAuto(option, index, type) {
      console.log("option->", option);
      console.log("index->", index);
      console.log("type->", type);
      if (type == "buttons" && option.options.length < 1) {
        this.addSubOption(option, index);
        delete option.selectedIntent;
        delete option.url;
      } else if (type == "answer") {
        option.options = [];
        delete option.url;
      } else {
        option.options = [];
        delete option.selectedIntent;
      }
    },
    addSubOption(option, index) {
      if (option.options?.length < 11) {
        if (!this.options[index].options) {
          this.$set(this.options[index], 'options', []);
        }
        const newOption = {
          label: '',
          action: '',
          value: {
            input: {
              text: '',
            },
          },
          options: [],
        };
        this.$set(
          this.options[index].options,
          this.options[index].options.length,
          newOption,
        );
      }
    },
    handleDragStart(index) {
      this.draggedItem = index;
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnd(index) {
      this.draggedItem = null;
    },
    handleDrop(index) {
      const droppedItem = this.options.splice(this.draggedItem, 1)[0];
      this.options.splice(index, 0, droppedItem);
      this.draggedItem = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  .item {
    border-radius: 1rem;
    transition: .75s;

    &:hover {
      transform:translateX(-.25rem);
      cursor: move;
    }
  }
  .col-lg-6 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  select {
    width: 100%;
  }
  .cart-label-top {
    border: 1px solid #cdcdcd;
    border-bottom: 0;
    background-color: #f2f2f2;
    border-radius: 1rem 1rem 0 0;

    &_dragged {
      border-color: blue;
    }
  }
  .label-cart {
    padding: 10px 0 0 15px;
  }
  .options-row {
    border: 1px solid #cdcdcd;
    border-radius: 0 0 1rem 1rem;
    &_dragged {
      border-color: blue;
    }
  }
  .delete-input {
    display: flex;
    justify-content: space-between;
  }
  .delete-input-option button {
    border: none;
    width: 50px;
    margin-bottom: 0rem;
    border-radius: 0 1rem 0 0;
    border-left: 1px solid #cdcdcd;
  }
  .delete-input-option_dragged button {
    border-left-color: blue;
  }
}
</style>
