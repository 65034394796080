<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="350"
    :show="showModal"
    @close="$emit('closeModal')"
  >
    <template slot="header">
      <h1 class="titulo__modal mb-4" />
    </template>
    <template slot="content">
      <h3 for="">Ingresa tu correo para enviar un enlace de recuperación.</h3>
      <section class="pass-recover">
        <DataInput
          label="Correo de recuperación"
          type="email"
          :model="mail_to_recover"
          :error="error"
          @newValue="recover = $event"
          required
        />
        <button @click.prevent="$emit('passRecover', recover)" class="btn btn-base mt-4">
          Enviar enlace
        </button>
      </section>
    </template>
  </Modal>
</template>

<script>
import DataInput from "@/components/builders/DataInput";

export default {
  components: {
    DataInput
  },
  props: ["showModal", "mail_to_recover", "error"],
  data() {
    return {
      loading: false,
      recover: ""
    };
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.pass-recover {
  width: 80%;
  padding: 2rem;
  margin: 2rem auto 1rem;
}
</style>
