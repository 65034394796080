<template>
  <div class="container"></div>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';

export default {
  data() {
    return {
      bot: [],
      qvo: [],
    };
  },

  computed: {
    ...mapState(['user', 'selected_bot']),

    id_bot() {
      return this.selected_bot.id;
    },
  },

  created() {
    const self = this;

    dashboard_api
      .get(`userbot/validation/${self.user.id}/${self.id_bot}`)
      .then((result) => {
        self.bot = result.data;

        dashboard_api
          .get(`/relief/validationqvo/${self.user.id}/${self.id_bot}`)
          .then((result) => {
            self.qvo = result.data;

            if (window.location.href.includes('/chatbot/edit/')) {
              if (self.bot && !self.qvo) {
              } else if (!self.bot && self.qvo) {
              } else if (!self.bot && !self.qvo) {
                self.$swal({
                  icon: 'error',
                  title:
                    'lo sentimos, pero no tienes permiso para editar este bot 🤖',
                });

                location.replace('/dashboard');
              }
            }
          });
      });
  },
};
</script>
