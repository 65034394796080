<template>
  <section class="success_pay">
    <FadeTransition :duration="200">
      <div class="success_pay-box">
        <div class="col-xs-5">
          <div class="box">
            <img class="success_icon mr-5" src="/img/icons/14-success_pay.svg" alt="">
            <div>
              <h1 class="title">¡Suscripción exitosa!</h1>
              <p class="mb-0">Felicidades, tu plan ha sido actualizado con éxito.</p>
              <router-link to="/dashboard" class="btn-text mt-4 d-block">&lt;- Volver al Dashboard</router-link>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from "@/dashboard_api";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      bots: [],
    };
  },
  mounted() {
    this.getBots();
  },
  computed: {
    ...mapState(['ownerBots', 'user', 'active_view']),
  },
  methods: {
    async getBots() {
      const self = this;
      const owner = self.active_view === "my_bots";

      let resp = (await dashboard_api.get(`/bot/list/${self.user.id}?owner=${owner}`)).data;

      if (self.filterShow) {
        // bots filtrados por business show
        self.bots = resp.filter((el) => self.evaluateToShow(el.id));
      } else {
        self.bots = resp;
        self.bots.sort((a, b) => a.activated > b.activated ? -1 : 1);
      }

      let selected = self.bots.find(el => el.id == self.$route.params.id);
      self.$store.commit("setOwnerBots", self.bots);
      self.$store.commit("setSelectedBot", selected);
    },
  }
};
</script>

<style lang="scss" scoped>
.success_pay {
  &-box {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

    .box {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 991px) {
        display: block;
        align-items: center;
        margin: 2rem auto;

        .success_icon {
          margin-bottom: 2.5rem;
        }
      }
    }
    .title {
      font-size: 30px;
    }
  }
}
</style>
