<template>
  <div class="preview">
    <SpinnerLoading v-if="loading" />
    <img
      @load="loading = false"
      :src="
        'https://api.apiflash.com/v1/urltoimage?access_key=2074b0fa94bb4eaaa8bfcf38155b15fd&amp;full_page=true&amp;url=' +
        getBotWebUrl()
      "
      width="100%"
      id="ChatbotScreen"
    />
  </div>
</template>

<script>
import environment from '@/environment';
import { insertSoporteBotDatabot } from '@/utils/utils';

export default {
  data() {
    return {
      loading: true,
      botId: null,
      token: null,
      web: null,
    };
  },
  mounted() {
    this.initialize();
    this.getBotScript();
    this.getBotWebUrl();
  },
  watch: {
    selectedBot() {
      this.scrollDown();
    },
  },
  computed: {
    selectedBot() {
      return this.$store.state.selected_bot;
    },
    botUrl() {
      return environment.url_env_bot_front;
    },
    pathname() {
      return window.location.pathname;
    },
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {
    initialize() {
      const { bot_id } = this.$route.params;
      const { token, web } = this.$route.query;
      this.botId = bot_id || this.selectedBot.id;
      this.token = token || this.selectedBot.token;
      this.web = web;
    },
    getBotScript() {
      let domain = this.botUrl;
      let previousBot = document.querySelector('#chat_box_identifier');
      let previousWhatsappWidget = document.querySelector(
        '#whatsapp_box_identifier',
      );
      if (previousBot) previousBot.remove();
      if (previousWhatsappWidget) previousWhatsappWidget.remove();

      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${domain}/dist_files/databot.js`;
      script.id = this.botId;
      script.setAttribute('bot', this.token);
      script.setAttribute('isPlayground', true);
      document.getElementsByTagName('body')[0].appendChild(script);
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${domain}/dist_files/databot.css`;
      document.getElementsByTagName('body')[0].appendChild(link);
    },
    getBotWebUrl() {
      // get param web from url
      return this.web
        ? this.web.startsWith('https://')
          ? this.web
          : `https://${this.web}`
        : this.selectedBot.web
        ? this.selectedBot.web.startsWith('https://')
          ? this.selectedBot.web
          : `https://${this.selectedBot.web}`
        : `https://www.databot.cl`;
    },
    scrollDown() {
      window.scrollBy(0, 70);
    },
  },
  beforeDestroy() {
    let previousBot = document.querySelector('#chat_box_identifier');
    let previousWhatsappWidget = document.querySelector(
      '#whatsapp_box_identifier',
    );
    if (previousBot) previousBot.remove();
    if (previousWhatsappWidget) previousWhatsappWidget.remove();
    insertSoporteBotDatabot();
  },
};
</script>

<style lang="scss"></style>
