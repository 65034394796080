<template>
  <FadeTransition :duration="200" origin="center top">
    <section class="login">
      <section class="inicia-sesion">
        <h1 class="title text-center my-5">Inicia sesión</h1>
        <div class="card">
          <DataInput
            label="Correo"
            type="email"
            :model="model.email"
            :error="error == 'empty_email'"
            @newValue="model.email = $event"
            required
          />
          <DataInput
            label="Contraseña"
            type="password"
            :model="model.password"
            :error="error == 'empty_pass'"
            @newValue="model.password = $event"
            @sendEnterEvent="login"
            required
          />
          <!-- <div class="d-flex align-items-center mb-3">
        <input type="checkbox" />
        <p class="mb-0 ml-2">Recordarme</p>
      </div> -->
          <p class="text-center mb-4">
            ¿Olvidaste tu contraseña?<span
              class="ml-2 link click"
              @click="show = true"
              >Recuperar</span
            >
          </p>
          <button
            :disabled="loading"
            @click="login"
            class="btn btn-base w-100 second lg my-4"
          >
            Iniciar sesión
          </button>
          <v-facebook-login
            :login-options="{
              scope: 'pages_show_list,business_management',
            }"
            style="margin: auto"
            @login="facebookLogged"
            app-id="280308108047238"
            class="mb-3 fb-login"
          ></v-facebook-login>
          <div class="mb-4">
            <p class="text-center">
              ¿No tienes cuenta?
              <router-link
                class="link ml-1"
                :to="{
                  path: '/registro',
                  query: {
                    shop: $route.query.shop,
                    access_token: $route.query.access_token,
                    token_access: $route.query.access_token,
                    shop_domain: $route.query.shop_domain || platform_name,
                    code: $route.query.code,
                  },
                }"
                >Regístrate -></router-link
              >
            </p>
          </div>
          <PassRecoverModal
            :showModal="show"
            :mail_to_recover="mail_to_recover"
            :error="error == 'empty_mail'"
            @closeModal="show = false"
            @passRecover="(mail_to_recover = $event), passRecover()"
          />
          <!-- <Modal ancho="500" :show="show" @close="show = false">
        <template v-slot:content @close="show = false">
          <div class="px-5">
            <label class="my-4">Ingresa tu correo para enviar un enlace de recuperación.</label>
            <input
              class="mb-1"
              :class="{ input_error: error == 'empty_mail' }"
              placeholder="nombre@empresa.cl"
              type="mail"
              v-model="email"
              required
            />
            <span v-if="error == 'empty_mail'" class="text_error text-center"
              >Ingresa un correo válido</span
            >
            <div class="text-center">
              <button @click.prevent="passRecover" class="btn btn-base my-4">
                Enviar enlace
              </button>
            </div>
          </div>
        </template>
      </Modal> -->
        </div>
      </section>
    </section>
  </FadeTransition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import FormLogin from '@/views/V2/Auth/components/Form_Login.vue';
import { FadeTransition } from 'vue2-transitions';
import dashboard_api_login from '@/dashboard_api.js';
// import dashboard_api_login from "@/dashboard_api_login.js";
import DataInput from '@/components/builders/DataInput';
import PassRecoverModal from '@/views/V2/Auth/components/PassRecoverModal';
import graphApiService from '@/services/api/graphApi.js';

export default {
  name: 'login',

  components: {
    FormLogin,
    FadeTransition,
    DataInput,
    PassRecoverModal,
    VFacebookLogin,
  },
  data() {
    return {
      mail_to_recover: '',
      model: {
        email: '',
        password: '',
      },
      error: '',
      loading: false,
      show: false,
    };
  },

  computed: {
    ...mapState(['platform_name']),

    loginq() {
      return this.$route.query.login;
    },
    bot_id() {
      return this.$route.query.bot_id;
    },
  },

  methods: {
    ...mapMutations(['loginAction']),
    async facebookLogged(e) {
      if (e) {
        this.$swal.showLoading();
        console.log('🚀 Aqui *** -> e', e);
        // solo para fines de test, se logea con USUARIO TEST
        const { accessToken, userID } = e.authResponse;
        console.log('🐞 LOG HERE userID:', userID);
        console.log('🐞 LOG HERE accessToken:', accessToken);
        // set to localstorage
        localStorage.setItem('facebookAccessToken', accessToken);
        graphApiService
          .getUserInformation(e.authResponse.accessToken)
          .then((resp) => {
            console.log('User meta: ', resp);
            // set to store
            if (this.$store.state.user) {
              this.$store.state.user.name = resp.data.payload.name;
            }
          });
        this.model.email = 'victor@databot.cl';
        this.model.password = '123456';
        this.login();
      }
    },

    login() {
      const self = this;
      const { email, password } = self.model;

      if (email.length == 0) self.error = 'empty_email';
      else if (password.length == 0) self.error = 'empty_pass';
      else {
        self.error = '';
        self.loading = true;

        self.$swal({
          icon: 'info',
          title: 'Iniciando...',
          showConfirmButton: false,
        });
        self.$swal.showLoading();

        dashboard_api_login
          .post('/auth/sign_in', {
            email: self.model.email,
            password: self.model.password,
          })
          .then((result) => {
            self.$store.commit('authModule/credentialsStorage', {
              ...result.headers,
            });

            dashboard_api_login
              .post('/bot/list_bot_ids_by_user', {
                user_id: result.data.data.id,
              })
              .then((botsResponse) => {
                dashboard_api_login
                  .get(
                    `/client_facturacion_data/by_user_id/${result.data.data.id}`,
                  )
                  .then((clientDataResponse) => {
                    if (clientDataResponse.data != null) {
                      self.loginAction({
                        user: result.data.data,
                        customerId: clientDataResponse.data.costumer_id,
                        token: result.headers['access-token'],
                        botIds: botsResponse.data,
                      });

                      self.$swal({
                        icon: 'success',
                        title: 'Has iniciado sesión exitosamente',
                        timer: 1000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                      });
                      // La ruta donde se dirigira el usuario logeado, variara con su plan
                      if (!self.loginq) {
                        if (self.platform_name == 'jumpseller') {
                          self.$router.push({
                            name: 'integration-install',
                            query: {
                              token_access: self.$route.query.token_access,
                              platform: self.platform_name,
                              shop_domain: self.$route.query.shop_domain,
                            },
                          });
                        } else {
                          self.$router.push('/dashboard');
                        }
                      } else {
                        self.$router.push({
                          name: 'register',
                          query: { activar: 'activate', bot_id: self.bot_id },
                        });
                      }
                    } else {
                      dashboard_api_login
                        .post('/flow_requests/create_customer', {
                          flow_request: {
                            name: result.data.data.name,
                            email: result.data.data.email,
                            externalId: result.data.data.id,
                            // externalId: `${result.data.data.email}_${result.data.data.id}`
                          },
                        })
                        .then((flowCustomerDataCreateResponse) => {
                          self.loginAction({
                            user: result.data.data,
                            customerId:
                              flowCustomerDataCreateResponse.data.costumer_id,
                            token: result.headers['access-token'],
                            botIds: botsResponse.data,
                          });

                          self.$swal({
                            icon: 'success',
                            title: 'Has iniciado sesión exitosamente',
                            timer: 1000,
                            timerProgressBar: false,
                            showConfirmButton: false,
                          });
                          // La ruta donde se dirigira el usuario logeado, variara con su plan
                          if (!self.loginq) {
                            if (self.platform_name == 'jumpseller') {
                              self.$router.push({
                                name: 'integration-install',
                                query: {
                                  token_access: self.$route.query.token_access,
                                  platform: self.platform_name,
                                  shop_domain: self.$route.query.shop_domain,
                                },
                              });
                            } else {
                              self.$router.push('/dashboard');
                            }
                          } else {
                            self.$router.push({
                              name: 'register',
                              query: {
                                activar: 'activate',
                                bot_id: self.bot_id,
                              },
                            });
                          }
                        })
                        .catch((error) => console.error('error ->', error));
                    }
                    self.loading = false;
                  })
                  .catch((error) => console.error('error ->', error));
                // self.loginAction({ user: result.data.data, token: result.headers["access-token"] });
              });
          })
          .catch((error) => {
            self.loading = false;
            self.$swal({
              icon: 'error',
              title: 'Error al loguearse',
              text: 'Su correo o contraseña son incorrectos',
            });
          });
      }
    },

    passRecover() {
      const self = this;

      if (self.mail_to_recover === '') {
        self.error = 'empty_mail';
      } else {
        self.$swal({
          icon: 'info',
          title: 'Enviando...',
          showConfirmButton: false,
        });
        self.$swal.showLoading();

        dashboard_api
          .post('/password/forgot', {
            email: self.mail_to_recover,
          })
          .then((response) => {
            if (response.data.status == 'ok') {
              self.$swal({
                icon: 'success',
                title: 'Correo de recuperación enviado',
                text: 'Si no ves el correo, recuerda revisar tu carpeta de spam',
              });
              self.error = '';
              self.show = false;
            }
          })
          .catch((err) => {
            console.log(err);
            self.$swal({
              icon: 'error',
              title: 'Error al enviar el correo',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    height: 100%;
  }
}

.inicia-sesion {
  width: 100%;

  @media (max-width: 480px) {
    margin: 2rem auto;
  }
  .title {
    font-size: 35px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  .card {
    margin: auto;
    border: none;
    background-color: transparent;
    width: 450px;
    z-index: 3;
  }
  .btn-base {
    font-size: 1rem;
  }
}
</style>
