<template>
  <section class="recover-pass">
    <h1 class="title text-center my-5">Reestablecer contraseña</h1>
    <div class="card">
      <DataInput
        label="Nueva contraseña"
        type="password"
        :model="model.password"
        :error="error == 'pass_0' || error == 'pass_length'"
        @newValue="model.password = $event"
        min="6"
        required
      />
      <DataInput
        label="Repite tu nueva contraseña"
        type="password"
        :model="model.password_confirmation"
        :error="error == 'confirm_pass_0' || error == 'password_not_match'"
        @newValue="model.password_confirmation = $event"
        required
      />
      <div class="text-center">
        <button
          class="btn btn-base second lg my-4"
          @click.prevent="resetPass"
          >Guardar contraseña
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import DataInput from "@/components/builders/DataInput";

export default {
  name: 'password_resets',
  components: {
    DataInput
  },
  data() {
    return {
      model: {
        password: '',
        password_confirmation: '',
        token: '',
      },
      error: '',
    };
  },

  computed: {
    ...mapState(['logged']),
  },

  methods: {
    resetPass() {
      const self = this;
      const { password, password_confirmation } = self.model;

      if (password.length == 0) self.error = 'pass_0';
      else if (password.length < 6) self.error = 'pass_length';
      else if (password_confirmation.length == 0) self.error = 'confirm_pass_0';
      else if (password != password_confirmation) self.error = 'password_not_match';
      else {
        self.error = '';
        self.model.token = self.$route.params.token;

        dashboard_api.post('/password/reset', {
          password: self.model.password,
          token: self.model.token,
        })
          .then((result) => {
            if (result.data.status == 'ok') {
              self.$swal({
                icon: 'success',
                title: 'Has cambiado de contraseña correctamente',
                html:
                  '<div class="d-flex justify-content-center mt-4"><a class="btn btn-base lg" href="https://ia.databot.cl/login">Iniciar sesión</a></div> ',
                showConfirmButton: false,
              });
              console.log('Listo->');
            };
          })
          .catch((err) => {
            console.log('error', err);

            self.$swal({
              icon: 'error',
              title: 'Error al cambiar tu contraseña',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recover-pass {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 480px) {
    margin: 2rem auto;
  }
  .title {
    font-size: 35px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  .card {
    box-shadow: none;
    margin: 0 auto;
    border: none;
    width: 450px;
    z-index: 3;
  }
}
</style>
