<template>
  <FadeTransition :duration="200" origin="center top">
    <section class="login">
      <FormLogin />
    </section>
  </FadeTransition>
</template>

<script>
import FormLogin from '@/views/V2/Auth/components/Form_Login.vue';
import { FadeTransition } from "vue2-transitions";

export default {
  name: 'login',

  components: {
    FormLogin,
    FadeTransition
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;

  @media (max-width: 991px) {
    height: 100%;
  }
}
</style>
