<template>
  <section class="soporte">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 mt-6">
          <h2>¿Necesitas ayuda?</h2>
          <p>Puedes comunicarte con nosotros con los siguientes datos:
          </p>
          <div class="row my-5 justify-content-center">
            <div class="col-lg-8 pl-0">
              <div class="card">
                <label><i class="fas fa-phone" />Teléfono</label>
                <p>+569 9242 3618</p>
                <label><i class="fas fa-envelope" />Correo</label>
                <p>hola@databot.cl</p>
                <label><i class="fas fa-map-marker-alt" />Direccion</label>
                <p class="mb-0">Mariano Sánchez Fontecilla Nº 310. Las Condes. Chile.</p>
              </div>
              <div class="row my-5 justify-content-between align-items-center">
                <div
                  v-for="item in info"
                  :key="item.id"
                >
                  <a
                    class="d-flex align-items-center justify-content-between"
                    :href="item.link"
                  >
                    <div class="rrss_box">
                      <i class="mx-0" :class="item.class" />
                    </div>
                    <p class="mb-0 ml-2">{{ item.label }}</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <label class="mb-5">Documentación</label>
              <div>
                <p><i class="fas fa-file-pdf" />
                  <a class="" target="_blank" href="/img/archivos/Guia-de-implementacion.pdf">Guía de implementación
                  </a>
                </p>
                <p><i class="fas fa-file-pdf" />
                  <a class="" target="_blank" href="/img/archivos/Guia-de-instalacion-wordpress.pdf">Implementación WordPress
                  </a>
                </p>
                <p><i class="fas fa-mouse-pointer" />
                  <a class="" target="_blank" href="https://databot.cl/documentacion/guia-jumpseller">Implementación Jumpseller
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-8 px-0 my-5 pb-5">
            <router-link class="btn-text" to="/dashboard"
              >&lt;- Volver</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      info: [
        {
          link: 'https://www.facebook.com/Databotoficial/',
          class: 'fab fa-facebook-f',
          label: '/Databotoficial',
        },
        {
          link: 'https://www.instagram.com/databot_oficial/',
          class: 'fab fa-instagram',
          label: '/databot_oficial',
        },
        {
          link: 'https://www.linkedin.com/company/databotchile/',
          class: 'fab fa-linkedin-in',
          label: '/databotchile',
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.soporte {
  width: 100%;

  .card {
    padding: 2rem;
  }
  .rrss_box {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1rem;
      color: #fff;
    }
  }
}
</style>
