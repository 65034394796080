<template>
  <div class="container"></div>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';

export default {
  data() {
    return {
      rolagente: [],
    };
  },

  computed: {
    ...mapState(['user', 'selected_bot']),

    id_bot() {
      return this.selected_bot.id;
    },
  },

  created() {
    this.checkRole();
  },

  methods: {
    checkRole() {
      const self = this;
      dashboard_api
        .get(`/userbot/role_for_user_bot/${self.user.id}/${self.id_bot}`)
        .then((result) => {
          self.rolagente = result.data;

          if (window.location.href.includes('/chatbot/edit/')) {
            if (self.rolagente && !self.rolagente.role.bot_update) {
              self.$swal({
                icon: 'error',
                title:
                  'lo sentimos, pero no tienes permiso para editar este bot 🤖',
              });

              location.replace('/dashboard');
            }
          }
        });
    },
  },
};
</script>
